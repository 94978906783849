// context.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { TOKEN, ADDRESS, NFTStaking } from './config';
import ABIS from '../src/abis/ABIS.json';
import STAKENFTABI from '../src/abis/STAKENFTABI.json'
import TOKENABI from '../src/abis/TOKENABI.json'
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import { EthereumProvider } from '@walletconnect/ethereum-provider';

export const Web3Context = createContext();
const NFTAbi = ABIS;
const Tokenabi = TOKENABI;
const StakeNftAbi = STAKENFTABI;

const providerOptions = {
	binancechainwallet: {
		package: true
	  },
	walletconnect: {
		package: EthereumProvider,
    	chainId: 56,
		// options: {
		//   infuraId: "7e41cbc3390649ca9a20fa79c3645a08",
    //   chains: [56],
    //   rpcMap: "https://solitary-sparkling-choice.bsc.discover.quiknode.pro/53983995fef46e59412667cbdb8605e23d71b93c/"
		// }
	  },
	//   walletlink: {
	// 	package: WalletLink, 
	// 	options: {
	// 	  infuraId: "7e41cbc3390649ca9a20fa79c3645a08",
	// 	  jsonRpcUrl: "https://solitary-sparkling-choice.bsc.discover.quiknode.pro/53983995fef46e59412667cbdb8605e23d71b93c/", 
	// 	  chainId: 56, 
	// 	}
	//   },
};


export const Web3Provider = ({ children }) => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [vaultContract, setVaultContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [web3Modal, setWeb3Modal] = useState(() => new Web3Modal({
        network: "BNB", // Điều chỉnh lại nếu bạn muốn sử dụng network khác
        cacheProvider: true,
        providerOptions,
    }));

    useEffect(() => {
      const initWeb3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions,
      });
      setWeb3Modal(initWeb3Modal);
    }, []);

    // Hàm để cập nhật trạng thái khi kết nối ví và thiết lập các contract
    const connectWallet = async () => {
      if (!web3Modal) return;
        try {
            const provider = await web3Modal.connect();
            const web3Instance = new Web3(provider);

            const accounts = await web3Instance.eth.getAccounts();
            if (accounts.length > 0) {
                const account = accounts[0];
                setWeb3(web3Instance);
                setAccount(account);
                setContract(new web3Instance.eth.Contract(NFTAbi, ADDRESS));
                setVaultContract(new web3Instance.eth.Contract(StakeNftAbi,NFTStaking));
                setTokenContract(new web3Instance.eth.Contract(Tokenabi,TOKEN));
                // Thiết lập các contract tương tự ở đây
                // Ví dụ: setContract(new web3Instance.eth.Contract(ABIS, ADDRESS));
            }
        } catch (error) {
            console.error(error);
        }
    };
    const mint = async (account, selectedValue) => {
        if (!web3 || !contract || !account) return;
        try {
          const mintAmount = Number(selectedValue);
          console.log(mintAmount)
          const mintRate = BigInt(await contract.methods.getCost().call());
          const mintRateInt = (mintRate.toString())
          const totalAmount = (mintRateInt * mintAmount).toString();
          console.log (totalAmount)
          const gasLimit = await contract.methods.mint(account, mintAmount.toString()).estimateGas({
            from: account,
            value: totalAmount,
          });
          const receipt = await contract.methods.mint(account, mintAmount.toString())
				.send({ from: account, value: totalAmount, gas: gasLimit, data: contract.methods.mint(account, mintAmount.toString()).encodeABI() })
				console.log('Transaction Hash:', receipt.transactionHash);
				console.log('Confirmation BigInt:', receipt.confirmationNumber);
		
				if (receipt.confirmationNumber === 1) {
				console.log('Transaction confirmed:', receipt);
				}
          // Xử lý thêm nếu cần
        } catch (error) {
          console.error("Error minting NFT:", error);
        }
    };

    const mint0 = async (account, selectedValue) => {
        if (!web3 || !contract || !account) {
            console.log('Web3, contract, or account is not initialized.');
            return;
        }
        try {
            const _pid = '0';
            const erc20address = await contract.methods.getCryptotoken(_pid).call();
            const currency = new web3.eth.Contract(TOKENABI, erc20address);
            const mintRate = BigInt(await contract.methods.getNFTCost(_pid).call());
            const mintRateInt = mintRate.toString();
            const totalAmount = (mintRateInt * selectedValue).toString();
            await currency.methods.approve(ADDRESS, totalAmount).send({ from: account });
            console.log("Approved:", totalAmount);
            const gas = await currency.methods.transfer(ADDRESS, totalAmount).estimateGas({ from: account });
            await currency.methods.transfer(ADDRESS, totalAmount).send({ from: account, gas });
            console.log("Transfer successful");
            // Additional mint0 logic here
        } catch (error) {
            console.error('Error in mint0:', error);
    }}

    const enable = async () => {
      if (!web3 || !contract || !account) {
        console.log('Web3, contract, or account is not initialized.');
        return;
      }
      try {
        const approved = contract.methods.setApprovalForAll(NFTStaking, true);
        const gasLimit = await contract.methods.setApprovalForAll(NFTStaking, true).estimateGas({
          from: account,
          });
        const tx = await approved.send({from: account, gas: gasLimit, data: contract.methods.setApprovalForAll(NFTStaking,true).encodeABI()});
        console.log(tx)

      } catch (error) {
        console.error("Error minting NFT:", error);
    }}

    const redeemRewards = async() => {
      if (!web3 || !vaultContract || !account) {
        console.log('Web3, contract, or account is not initialized.');
        return;
      }
      try {
        // Gọi hàm redeem từ contract NFTStaking
        const redeemFunction = vaultContract.methods.redeem();
        const gasLimit = await vaultContract.methods.redeem().estimateGas({
        from: account,
        });
        // Gửi giao dịch
        const tx = await redeemFunction.send({ from: account, gas: gasLimit, data: vaultContract.methods.redeem().encodeABI() });
      
        // Xử lý kết quả giao dịch (nếu cần)
        console.log('Redeem Transaction Hash:', tx.transactionHash);
      } catch (error) {
        console.error('Error redeeming rewards:', error);
      }
    }

    return (
        <Web3Context.Provider value={{ web3, web3Modal,account, contract, vaultContract, tokenContract, connectWallet, mint, mint0, redeemRewards }}>
            {children}
        </Web3Context.Provider>
    );
};

export const useWeb3 = () => useContext(Web3Context);