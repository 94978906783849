import React, { useContext, useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { ADDRESS, moralisapi, NFTStaking, nftpng } from '../config';
import { useWeb3  } from '../context.js';
import logo from "../assets/logo.png"
// import Web3 from 'web3';


const moralisapikey = "2VBV4vaCLiuGu6Vu7epXKlFItGe3jSPON8WV4CrXKYaNBEazEUrf1xwHxbrIo1oM";

export default function Artworks() {
  const [apicall, getNfts] = useState([])
  const [nftstk, getStk] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  // const [isStaked, setIsStaked] = useState(false);
  const { account, vaultContract, contract, connectWallet, redeemRewards } = useWeb3();
  useEffect(() => {
    if (!account || !vaultContract || !contract) {
      connectWallet(); // Tự động kết nối ví khi component được mount
    }
    callApi(); // Bạn có thể muốn chạy hàm này sau khi ví được kết nối thành công
  }, [account, vaultContract, contract]);  
  
  async function callApi() {
    if (!web3 || !vaultContract || !account || !contract) return;
		let config = {'X-API-Key': moralisapikey, 'accept': 'application/json'};
		const nfts = await axios.get((moralisapi + `/nft/${ADDRESS}/owners?chain=bsc&format=decimal`), {headers: config})
    .then(output => {
        const { result } = output.data
        return result;
      })
    const apicall = await Promise.all(nfts.map(async i => {
      let item = {
        tokenId: i.token_id,
        holder: i.owner_of,
        wallet: account,
      }
      return item
    }))
    const stakednfts = await vaultContract.methods.getStakedTokens(account).call()
    .then(id => {
    if (Array.isArray(id)) {
        return id; // Nếu id là mảng, trả về id
    } else {
        return [id]; // Nếu id không phải mảng, chuyển nó thành mảng gồm một phần tử
    }
    })
    console.log(stakednfts,"moon")
    const nftstk = await Promise.all(stakednfts.map(async i => {
      let stkid = {
        tokenId: i,
      }
      return stkid;
    }))
    // nftstk.forEach(item => {
    //   console.log(item.tokenId); // In ra giá trị của tokenId cho từng đối tượng
    // });
      getNfts(apicall)
      getStk(nftstk)
    //   console.log(nftstk, "stk")
    //   console.log(apicall,"apicall");
      setLoadingState('loaded')
    }
    // if (loadingState === 'loaded' && !apicall.length) 
    // return (
    //       <h1 className="text-3xl">Wallet Not Connected</h1>)
          return (
            <div className='mb-4 bg-[#131835] py-10 grid justify-items-center'>
                <h4 className="text-gradient uppercase text-3xl font-bold">Unique in NFTs.</h4>
                <button className='border-4 rounded-xl text-indigo-800 bg-yellow-500 p-2 mx-64 mt-2 font-bold hover:bg-yellow-400' onClick={redeemRewards}>Claim Reward
                
                </button>
                <div className="container col-lg-11 ">
                  <div className="row items px-3 pt-3 ">
                    <div className="ml-3 mr-3 grid grid-cols-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-11 ">
                      {apicall.map((nft, i) => {
                        var owner = nft.wallet.toLowerCase();
                          if (owner.indexOf(nft.holder) !== -1) {
                          async function stakeNFT(tokenID) {
                            try {
                              // Kiểm tra xem NFT đã được stake chưa
                                const approved = contract.methods.setApprovalForAll(NFTStaking, true);
                                const gasLimit1 = await contract.methods.setApprovalForAll(NFTStaking, true).estimateGas({
                                  from: account,
                                  });
                                const tx1 = await approved.send({from: account, gas: gasLimit1, data: contract.methods.setApprovalForAll(NFTStaking,true).encodeABI()});
                                console.log(tx1)
                                const stakeFunction = vaultContract.methods.stake(tokenID);
                                const gasLimit = await vaultContract.methods.stake(tokenID).estimateGas({
                                  from: account,
                                  data: vaultContract.methods.stake(tokenID).encodeABI()
                                });
                                const tx = await stakeFunction.send({ from: account, gas: gasLimit, data: vaultContract.methods.stake(tokenID).encodeABI() });
                                console.log("Transaction:", tx);
                                console.log('Stake Transaction Hash:', tx.transactionHash);
        
                        
                            } catch (error) {
                              console.error('Error staking NFT:', error);
                            }
                          }
                        return (
                          <div className="card nft-card mt-3 mb-3 justify-items-center bg-indigo-200 border-solid border-8 border-blue-500" key={i} >
                            <div className="image-over ">
                              <img className="card-img-top " src={nftpng + nft.tokenId + '.jpg'} alt="" />
                            </div>
                            <div className="card-caption col-12 p-0 ml-3">
                              <div className="card-body">
                                <h5 className="mb-0 text-blue-600 font-bold">Collection NFT #{nft.tokenId}</h5>
                                <h5 className="mb-0 mt-2 font-semibold text-indigo-700">Status<p className='text-indigo-600 font-extrabold'>Ready to Stake</p></h5>
                                <div className="card-bottom d-flex justify-content-between">
                                  <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                                  <button className='bg-gradient-to-r from-blue-400 to-purple-300  mb-2 p-2 text-primary font-bold rounded-lg focus:ring-2 focus:ring-blue-600' onClick={() => stakeNFT(parseInt(nft.tokenId))}>Stake it</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}})}
                        {nftstk.map((nft, i) => {
                        async function withdrawNFT(tokenID) {
                          try {
                            // Kiểm tra xem NFT đã được unstake chưa
                              const withdrawFunction = vaultContract.methods.withdraw(tokenID);
                              const gasLimit = await vaultContract.methods.withdraw(tokenID).estimateGas({
                                from: account,
                              });
                              const tx = await withdrawFunction.send({ from: account, gas: gasLimit, data: vaultContract.methods.withdraw(tokenID).encodeABI() });
                              console.log('Withdraw Transaction Hash:', tx.transactionHash);
                          } catch (error) {
                            console.error('Error withdrawing NFT:', error);
                          }
                        }
                          return (                            
                            <div className="card stakedcard mt-3 mb-3 justify-items-center bg-red-200 border-solid border-8 border-red-500" key={i} >
    
                              <div className="image-over">
                              <img style={{position:'absolute',width:'50px'}} src={logo}></img>
                                <img className="card-img-top" src={nftpng + nft.tokenId + '.jpg'} alt="" />
                              </div>
                              <div className="card-caption col-12 p-0 ml-3">
                                <div className="card-body">
                                  <h5 className="mb-0 text-yellow-500 font-bold">Collection NFT {nft.i} </h5>
                                  <h5 className="mb-2 mt-2 font-semibold text-white">Status<p className='text-red-600 font-extrabold'>Currently Staked</p></h5>
                                  <div className="card-bottom d-flex justify-content-between">
                                    <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                                    <button className='bg-gradient-to-r from-yellow-400 to-red-300  mb-2 p-2 text-primary font-bold rounded-lg focus:ring-2 focus:ring-red-600' onClick={() => withdrawNFT(parseInt(nft.tokenId))}>Withdraw</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )})}
                    </div>
                  </div>
                </div>
                </div>
            )    
  }