import logo from '../assets/logo.png'
import { useContext } from 'react';
import { truncate, useGlobalState } from '../store'
import { useWeb3, Web3Provider } from '../context.js';


const Header = () => {
  const { account, connectWallet } = useWeb3();


  return (
    <nav className="w-4/5 flex md:justify-center justify-between items-center py-4 mx-auto">
      <div className="flex flex-row justify-start items-center md:flex-[0.5] flex-initial">
        <img className="w-10 cursor-pointer" src={logo} alt="Adulam Logo" />
        <span className="text-white text-2xl ml-2 font-bold p-1">TRAGA NFTs</span>
      </div>

      <ul
        className="md:flex-[0.5] text-white 
        md:flex hidden list-none flex-row 
        justify-between items-center flex-initial"
      >
        <li className="mx-6cursor-pointer">Explore</li>
        <li className="mx-6 cursor-pointer">Features</li>
        <li className="mx-6 cursor-pointer">Community</li>
      </ul>

      {account ? (
        <button
          className="shadow-xl shadow-black text-white 
        bg-red-500 hover:bg-red-300 md:text-xs p-2 mx-4
        rounded-full cursor-pointer"
        >
          {truncate(account, 4, 4, 11)}
        </button>
      ) : (
        <button
          className="shadow-xl shadow-black text-white 
        bg-pink-500 hover:bg-pink-300 md:text-xs p-2 mx-4
        rounded-full cursor-pointer"
          onClick={connectWallet}
        >
          Connect Wallet
        </button>
      )}
    </nav>
  )
}

export default Header