import avatar from '../assets/owner.gif'
import xapp from '../assets/xapp.png'
import telegram from '../assets/telegram.png'
import opensea from '../assets/opensea.svg'
import scan from '../assets/scan.png'
// import logo from '../assets/logo.png'
import banner from '../assets/banner.jpg'
import eth from '../assets/eth.png'
import logo from '../assets/logo.png'
import axios from 'axios'
import { setAlert, setGlobalState, useGlobalState } from '../store'
import { ADDRESS, moralisapi, NFTStaking, nftpng, TOKEN, bscapi } from '../config';
import { Component, useContext, useState, useEffect } from 'react'
import { useWeb3 } from '../context'

const moralisapikey = "2VBV4vaCLiuGu6Vu7epXKlFItGe3jSPON8WV4CrXKYaNBEazEUrf1xwHxbrIo1oM";
const polygonscanapikey = "N6CGR485KYB8JV43I2RQR2YK2Y2EJDX2X8";


async function enable() {
	
  const approved = contract.methods.setApprovalForAll(NFTStaking, true);
	const gasLimit = await contract.methods.setApprovalForAll(NFTStaking, true).estimateGas({
		from: account,
		});
	const tx = await approved.send({from: account, gas: gasLimit, data: contract.methods.setApprovalForAll(NFTStaking,true).encodeABI()});
	console.log(tx)
}
async function redeemRewards() {
	try {
	  // Gọi hàm redeem từ contract NFTStaking
	  const redeemFunction = vaultContract.methods.redeem();
	  const gasLimit = await vaultContract.methods.redeem().estimateGas({
		from: account,
		});
	  // Gửi giao dịch
	  const tx = await redeemFunction.send({ from: account, gas: gasLimit, data: vaultContract.methods.redeem().encodeABI() });
  
	  // Xử lý kết quả giao dịch (nếu cần)
	  console.log('Redeem Transaction Hash:', tx.transactionHash);
	} catch (error) {
	  console.error('Error redeeming rewards:', error);
	}
}

function Hero() {
    const [showModal, setShowModal] = useState(false);
    const { mint, mint0, account } = useWeb3();
    const [selectedValue, setSelectedValue] = useState(null);
    const [currentMintFunction, setCurrentMintFunction] = useState(null);
  
    const [balance, setBalance] = useState({});
    const [nftData, setNftData] = useState([]);


    const handleOpenMintModal = (mintType) => {
      setShowModal(true);
      setCurrentMintFunction(() => mintType); // Lưu trữ hàm mint tương ứng
    };
    const handleConfirmMint = async () => {
      if (!selectedValue) return;
      // Gọi hàm mint tương ứng
      if (currentMintFunction === 'mint') {
          await mint(account,selectedValue);
      } else if (currentMintFunction === 'mint0') {
          await mint0(account, selectedValue);
      }
      setSelectedValue(null);
      setShowModal(false); // Đóng modal sau khi mint
  };
  const closeModal = () => {
    setSelectedValue(null);
    setShowModal(false);
  };

  const handleSelection = (e) => {
    const value = Number(e.target.value); // Make sure this conversion results in the expected numeric value
    setSelectedValue(value);
    console.log(value)
  };
  
  useEffect(() => {
    const fetchBalanceAndNftData = async () => {
      try {
        // Fetch balance
        const balanceResponse = await axios.get(bscapi +`?module=stats&action=tokensupply&contractaddress=${ADDRESS}&apikey=${polygonscanapikey}`);
        setBalance(Number(balanceResponse.data.result));

        // Fetch NFT data
        const config = { headers: { 'X-API-Key': moralisapikey, 'Accept': 'application/json' } };
        const nftResponse = await axios.get((moralisapi +`/nft/${ADDRESS}/owners?chain=mumbai&format=decimal`), config);
        setNftData(nftResponse.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBalanceAndNftData();
  }, []);
	// const sleep = (milliseconds) => {
	// 	return new Promise(resolve => setTimeout(resolve, milliseconds))
	// }
	// const expectedBlockTime = 10000;
  return (
    <div        
        className="bg-no-repeat bg-cover bg-center"
        style={{backgroundImage: `url(${banner})`}}
    >
      <div className="flex flex-col justify-center items-center mx-auto py-10">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-red-500 text-5xl font-bold text-center">
            Trump6900 <br />
            <span className="text-gradient text-black">NFTs Collection</span>
          </h1>

          <p className="text-white font-semibold text-xl mt-3">
            Mint and collect the hottest NFTs around.
          </p>
        <div className='flex flex-row gap-3'>
            <button
                className="shadow-xl shadow-black text-white
                bg-blue-900 hover:bg-blue-300 p-2
                rounded-full cursor-pointer my-4 font-bold flex justify-center items-center"
                onClick={() => handleOpenMintModal('mint')}
            >
                <img className='rounded-full w-10 h-10' src={eth} alt="" />
               <p className='p-2'>Mint with Ethereum</p>
            </button>

            <button
                className="shadow-xl shadow-black text-white
                bg-red-500 hover:bg-red-900 p-2 font-bold
                rounded-full cursor-pointer my-4 flex justify-center items-center"
                onClick={() => handleOpenMintModal("mint0")}
            >
                <img className='rounded-full w-10 h-10' src={logo} alt="" />
                <p className='p-2'>Mint with Token</p>
            </button>
            {/* Modal để chọn số lượng NFT */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
            <div className="bg-white p-5 rounded-lg shadow-lg text-center">
            <div
            id="modal-overlay" // Thêm id để xác định phần tử này khi click
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal} // Gọi hàm closeModal khi overlay được click
          >
            <div
              className="bg-white p-5 rounded-lg shadow-lg text-center"
              onClick={e => e.stopPropagation()} // Ngăn không cho sự kiện click lan ra ngoài
            >
              <h3 className="text-xl mb-4 font-bold">Select NFT Quantity</h3>
              <div className="btn-group cursor-pointer ">
                <button className='bg-blue-400 mx-3 w-10 h-10 rounded-full font-bold focus:bg-blue-900' value={1} onClick={handleSelection}>1</button>
                <button className='bg-blue-400 mx-3 w-10 h-10 rounded-full font-bold focus:bg-blue-900' value={2} onClick={handleSelection}>2</button>
                <button className='bg-blue-400 mx-3 w-10 h-10 rounded-full font-bold focus:bg-blue-900' value={3} onClick={handleSelection}>3</button>

              </div>
              <button
                className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-400"
                onClick={handleConfirmMint}
            >
                Confirm
            </button>
            <button className='py-1 mx-2 px-2 bg-red-500 rounded-2xl border-4 hover:bg-red-300' onClick={closeModal}>Cancel</button>
            </div>
          </div>
          </div>
          </div>
        )}
        </div>
            <img
              className="w-60 h-60 object-contain rounded-full"
              src={avatar}
              alt="Adulam Logo"
            />
          <p className="text-yellow-100 text-m font-bold text-center w-2/3">
          In this unique NFTs collection, witness the embodiment of a powerful symbol that ignited a movement of hope and perseverance. 
           $TRAGA captures the essence of a pivotal moment in history as it portrays the journey of reclaiming the presidency, led by none other than Donald J. Trump.
            Embrace the symbolism, embrace the movement, and become a part of something extraordinary with $TRAGA.
          </p>

          <ul className="flex flex-row justify-center space-x-2 items-center my-4">
            <a
              className="bg-white hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://twitter.com/TRAGAERC20"
            >
              <img className="w-16 h-16 rounded-full" src={xapp} alt="xapp" />
            </a>
            <a
              className="bg-white hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://t.me/TRAGA6900"
            >
              <img className="w-16 h-16" src={telegram} alt="telegram" />
            </a>
            <a
              className="bg-white hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://etherscan.io/"
            >
              <img className="w-16 h-16" src={scan} alt="scan" />
            </a>
            <a
              className="bg-white hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://opensea.io/"
            >
              <img className="w-16 h-16" src={opensea} alt="opensea" />
            </a>
            <a
              className="bg-no-repeat hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://trump6900coin.com"
            >
              <img className="w-16 h-16" src={logo} alt="logo" />
            </a>
          </ul>

          <div
            className="shadow-xl shadow-black flex flex-row
            justify-center items-center w-16 h-16 rounded-full
          bg-white cursor-pointer p-3 ml-4 text-black 
            hover:bg-red-700 hover:text-white transition-all
            duration-75 delay-100 text-xs font-medium"
          > {JSON.stringify(balance)}/1000
            {/* <span className="text-xs font-medium">/1000</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;